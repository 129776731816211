export function isElementInViewport(element: HTMLElement) {
  // if ([null, undefined].includes(element as any)) return false;

  var result: boolean;

  const rect = element.getBoundingClientRect();

  result =
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth);

  return result;
}
