import strings from "../../data/landing-page-content";
import AutoAnswerAnimationController from "./animation-controller";

function FeatureList() {
  AutoAnswerAnimationController();

  const featureItems = () => Object.values(strings.features);
  const imageMap = {
    "Save Time": "done-male.svg",
    "Optimize Your Workflow": "browser-lf.svg",
    "The Last Step Before Submitting": "document-upload.svg",
  } as any;

  return (
    <section className="feature-container padding-content" id="features">
      <div className="feature-list feature-list__container">
        {featureItems().map(({headline, body}, index) => {
          return (
            <>
              <div
                className="feature-list feature-list__copy"
                key={`${headline}`}
              >
                <h2 className="feature-list__headline headline-large">
                  {headline}
                </h2>
                <p className="feature-list__body body-large">{body}</p>
              </div>
              <div className={`img-container-${index + 1}`}>
                <img
                  key={`${body}`}
                  src={`${imageMap[headline]}`}
                  alt="Feature graphic"
                  className="feature-list__graphic"
                ></img>
                {index == 1 && <span id={`text-animation`}></span>}
              </div>
            </>
          );
        })}
      </div>
    </section>
  );
}

// interface FeatureItemProps {
//   headline: string;
//   body: string;
// }

// function FeatureItem({headline, body}: FeatureItemProps) {
//   return (
//     <div>
//       {headline}
//       {body}
//     </div>
//   );
// }

export default FeatureList;
