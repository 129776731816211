const strings = {
  title: "Grantyd | AI Grant Writing Automation",
  headline: "Successful Grant Writing Made Easy",
  subtitle:
    "Grantyd is your last step in grant writing. Our web extension uses your documents to auto-populate your grant applications right in the browser, giving you more time to write winning proposals.",
  cta: "Join The Beta",
  painPoints: {
    headline: "Sound familiar?",
    first:
      "Writing the same narratives over and over again, doing way too much copy & paste?",
    second:
      "Painful process of completing the application getting in the way of timely grant submissions?",
    third:
      "Needing to write more grants but don't have the time or the organizational structure in place?",
  },

  features: {
    first: {
      headline: "Save Time",
      body: "With our patent pending autofill functionality, we drastically reduce the hours you spend entering the same information across multiple grant applications. That efficiency means you can complete more grants, and in less time.",
    },
    second: {
      headline: "Optimize Your Workflow",
      body: "No more switching between tabs, windows, and documents. All your assets are organized and available right next to your applications, anywhere on the web. Quickly and efficiently work on multiple grant applications and eliminate the frustration that comes with copy and paste.",
    },
    third: {
      headline: "The Last Step Before Submitting",
      body: "You've already written the grant—let Grantyd finish the job. We handle the tedious task of filling out the application, leaving you with just one step: submit.",
    },
    // fourth: {
    //   headline: "Use AI Ethically",
    //   body: "Quickly and efficiently work on multiple grant applications and eliminate the frustration that comes with copy and paste.",
    // },
  },

  faq: {
    1: {
      question:
        "How does Grantyd prevent the AI from just making things up (hallucinating)?",
      answer:
        "The core of the application takes a dual strategy: one as a policy, and one as code. First, the reason we need your documents is so that the assistant has something to base its answers off of. Then, we instruct the AI service to only answers questions using the documents it has available (only yours). This way, your assistant answers only the questions that it can from your document locker, and will let you know which ones it couldn't complete, and why.",
    },
    2: {
      question: "What is Retrieval Augmented Generation (RAG?)",
      answer:
        'RAG is the process of using AI to reference an authoritative knowledge base (in this case what you write) to generate unique responses to your grant application questions. The formal technique is Retriaval Augmented Generation (RAG), and whenever you hear of a service using a "Knowledge Base" or "Data Corpus", that\'s what they\'re actually doing. ',
    },
    3: {
      question: "How secure are my documents?",
      answer:
        "Your documents are stored using the Google Cloud Storage service, which uses encryption to secure documents stored in its cloud storage, encrypting data both at rest and in transit. This scrambling process makes the data unreadable, even if someone gains unauthorized access.",
    },
  },

  gettingStarted: {
    1: {
      headline: "Create Your AI Knowledge Base",
      body: "Upload your relevant foundation/grounding documents (proposals, narratives, budgets, statistics, etc.).",
    },
    2: {
      headline: "Complete your Applications",
      body: "Use your Chrome extension to intelligently scan your grant application form.",
    },
    3: {
      headline: "Submit the grant and win the award",
      body: "More grants submitted means more grant dollars for your organization.",
    },
  },
  autoAnswerAnimationText:
    "At Acme International, to support our overall mission of supporting human services in the metro area, we have embarked on a special project aimed at helping at-risk youth to...",

  brandscript: "",
};

export default strings;
