import {addDoc, collection} from "firebase/firestore";
import {db} from "../../../infra/firebase/firebase";

interface FormSubmission {
  email: string;
  timeStamp: number;
}
async function joinTheWaitlist({email}: FormSubmission) {
  try {
    const waitlist = collection(db, "BetaWaitlist");
    await addDoc(waitlist, {email, timeStamp: new Date().valueOf()});
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return true;
  } catch (error) {
    console.error(error);
  }

  return false;
}

export default joinTheWaitlist;
export type {FormSubmission};
