import Logo from "./Logo";

function Footer() {
  return (
    <footer className="footer padding-content">
      <Logo />
      <p className="address body-medium">Atlanta, GA</p>

      <p className="body-small">
        &copy;2024, Syntactic Sugar LLC. All rights reserved.
      </p>
    </footer>
  );
}

export default Footer;
