import {FormEvent, FormEventHandler, useState} from "react";
import strings from "../../data/landing-page-content";
import joinTheWaitlist from "./controllers/join-the-waitlist";

import type {FormSubmission} from "./controllers/join-the-waitlist";

function Header() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState<boolean | undefined>(
    undefined
  );

  const onSubmit: FormEventHandler<HTMLFormElement> = async (
    event: FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setIsLoading(true);

    const formElement = event.target as HTMLFormElement;

    const form = new FormData(formElement);

    const formData = Object.fromEntries(
      form.entries()
    ) as unknown as FormSubmission;

    const submissionResult = await joinTheWaitlist(formData);

    // @ts-ignore
    if (submissionResult) (gtag_report_conversion as any)();
    setIsLoading(false);
    setIsSuccessful(submissionResult);
    formElement.reset();
  };

  return (
    <header className="header  padding-content" id="header">
      <div className="header-container">
        <div className="text-box">
          <h1 className="headline headline-xlarge">{strings.headline}</h1>
          <p className="subtitle body-large">{strings.subtitle}</p>
        </div>
        <div className="img-container">
          <iframe
            width="561"
            height="315"
            frameBorder="0"
            src="https://www.youtube.com/embed/d1qL8eMMS78"
            title="YouTube video player"
            allow="accelerometer;
 autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </div>
        <form
          className="cta-form"
          onSubmit={onSubmit}
          data-issuccessful={
            isSuccessful == undefined ? "undefined" : isSuccessful
          }
        >
          <input
            type="email"
            name="email"
            required
            placeholder="Enter your email"
            className="input input--email"
            aria-label="Beta signup email field"
          />
          <button className="cta-button" formAction="submit">
            {strings.cta}
          </button>
        </form>
        <div className="loader" data-isloading={isLoading}></div>
      </div>
    </header>
  );
}

export default Header;
