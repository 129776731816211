import {useEffect, useState} from "react";
import strings from "../../data/landing-page-content";
import {isElementInViewport} from "../../utils/helpers";

interface AutoAnswerAnimationProps {}

function AutoAnswerAnimationController({}: AutoAnswerAnimationProps = {}) {
  const [playAnimation, setPlayAnimation] = useState<boolean | null>(null);
  const [currentlyPlaying, setCurrentlyPlaying] = useState<boolean>(false);

  const typeText = async (element: HTMLSpanElement) => {
    const textToType = strings.autoAnswerAnimationText;
    const splitText = textToType.split("");

    for (const letter of splitText) {
      element.innerHTML += letter;
      await new Promise((resolve) => setInterval(resolve, 20));
    }
    await new Promise((resolve) => setInterval(resolve, 100));
    element.innerHTML = "";
    await new Promise((resolve) => setInterval(resolve, 100));
  };

  useEffect(() => {
    console.log("Loaded scroll watching UE");
    const togglePlayAnimation = () => {
      const graphic = document.querySelector(
        "#root > section.feature-container.padding-content > div > div.img-container-2 > img"
      ) as HTMLElement;
      const result = isElementInViewport(graphic);
      console.log(result);
      setPlayAnimation(result);

      return;
    };

    document.addEventListener("scrollend", togglePlayAnimation);

    // return () => document.removeEventListener("scrollend", togglePlayAnimation);
  }, []);

  useEffect(() => {
    console.log("Loaded player UE");
    const startTheTyping = async () => {
      while (playAnimation === true && !currentlyPlaying) {
        setCurrentlyPlaying(true);
        const graphic = document.getElementById("text-animation")!;
        setPlayAnimation(true);
        await typeText(graphic);
        setCurrentlyPlaying(false);
      }
    };

    startTheTyping();
  }, [playAnimation]);
}

export default AutoAnswerAnimationController;
