import {getAnalytics, } from "firebase/analytics";
import {FirebaseApp, initializeApp} from "firebase/app";
import {
  AppCheck,
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import {getFirestore} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDVAXHTt9CHmJaUso511POS7b3ao06yHoM",
  authDomain: "grantyd-prod.firebaseapp.com",
  projectId: "grantyd-prod",
  storageBucket: "grantyd-prod.appspot.com",
  messagingSenderId: "674299222441",
  appId: "1:674299222441:web:c18384946d22227e7bccb8",
  measurementId: "G-R7B7FF3RQ0",
};

// eslint-disable-next-line
if (location.hostname === "localhost") {
  // eslint-disable-next-line
  (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

var isInitialized = false;
var app: FirebaseApp;
var appCheck: AppCheck;

if (!isInitialized) {
  app = initializeApp(firebaseConfig);

  appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(
      "6Lcv6BUqAAAAAMZGflOmy4AuEW4reYj8tGAEU-I5"
    ),
    isTokenAutoRefreshEnabled: true,
  });
}

console.log(appCheck!);
const analytics = getAnalytics(app!);
const db = getFirestore(app!, "landing-page");

export {analytics, db};
