import {useEffect, useState} from "react";

function ScrollTop() {
  const [isScrollTop, setIsScrollTop] = useState<boolean>(true);

  const handleScroll = (_: Event) => {
    var result: boolean = true;

    if (window.scrollY !== 0) result = false;

    setIsScrollTop(result);

    return;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return isScrollTop;
}

export {ScrollTop};
