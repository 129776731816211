import "./App.css";
import FAQ from "./features/faq/FAQ";
import FeatureList from "./features/feature-list/FeatureList";
import Footer from "./features/footer/Footer";
import Header from "./features/header/Header";
import NavBar from "./features/navbar/NavBar";

function App() {
  return (
    <>
      <NavBar />
      <Header />

      <FeatureList />
      <FAQ />
      <Footer />
    </>
  );
}

export default App;
