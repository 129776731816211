import {useEffect, useState} from "react";

function OnMenuClick() {
  const [isMenuDisabled, setIsMenuDisabled] = useState(true);

  useEffect(() => {
    const menuDiv = document.querySelector(
      ".navbar-menu--items"
    ) as HTMLDivElement;

    menuDiv.setAttribute("style", `display:none !important`);
  }, []);

  const onMenuClick = () => {
    var newMenuDisplay = true;
    var displayStyle = "none";

    const menuDiv = document.querySelector(
      ".navbar-menu--items"
    ) as HTMLDivElement;

    if (isMenuDisabled) {
      displayStyle = "flex";
      newMenuDisplay = false;
    }

    menuDiv.setAttribute("style", `display:${displayStyle} !important`);
    setIsMenuDisabled(newMenuDisplay);
  };

  return {isMenuDisabled, onMenuClick};
}

export default OnMenuClick;
