import {ScrollTop} from "./utils/is-scroll-top";
import OnMenuClick from "./utils/on-menu-click";

function NavBar() {
  const isScrollTop = ScrollTop();
  const {isMenuDisabled, onMenuClick} = OnMenuClick();

  return (
    <nav className="navbar padding-content" data-isscrolltop={isScrollTop}>
      {/* <div className="navbar-blur-pane"></div> */}
      <img src="logo-black.svg" alt="Grantyd Logo" className="navbar__logo" />
      <NavBarLinks classNames="navbar-links" />
      <img
        className="navbar-menu--icon"
        src="menu-icon.svg"
        onClick={onMenuClick}
      ></img>
      <NavBarLinks
        classNames="navbar-menu--items"
        style={{display: "none !important"}}
      />
    </nav>
  );
}

function NavBarLinks({classNames = "", style = {}}) {
  const linkHrefs: {[key: string]: string} = {
    Home: "#header",
    Features: "#features",
    FAQ: "#faqs",
  };

  return (
    <div
      className={`navbar-link navbar-link__features btn ${classNames}`}
      style={style}
    >
      {Object.keys(linkHrefs).map((key) => (
        <a
          key={key}
          href={linkHrefs[key]}
          className={`navbar-link navbar-link__features btn`}
        >
          {key}
        </a>
      ))}
    </div>
  );
}

export default NavBar;
