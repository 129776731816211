import strings from "../../data/landing-page-content";

function FAQ() {
  const faqs = Object.values(strings.faq);
  return (
    <section className="faq padding-content" id="faqs">
      <h2 className="headline-large">Frequently Asked Questions</h2>
      <div className="faq-container">
        <dl>
          {faqs.map((faq) => (
            <details>
              <summary className="body-large">{faq.question}</summary>
              <p className="body-medium">{faq.answer}</p>
            </details>
          ))}
        </dl>
      </div>
    </section>
  );
}

export default FAQ;
